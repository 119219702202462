export const ICON_HTML = `<div style="display: none; visibility: hidden; width: 0; height: 0;">
   <svg xmlns="http://www.w3.org/2000/svg">


<!---------------------------------------------- 48 x 48 icons ------------------------------------------------------>

	<symbol viewBox="0 0 48 48" id="system-play">
		<path d="M19.5,16.4l10.4,7L19.5,30.6Zm4,29.1a22,22,0,1,0-22-22A22,22,0,0,0,23.5,45.5Z" transform="translate(1 1)" fill="none" stroke-width="1" fill-rule="evenodd" stroke="currentColor"/>
	</symbol>

	<symbol viewBox="0 0 48 48" id="audio-off">
		<path d="M87.5,45A22.5,22.5,0,1,1,110,22.5,22.525,22.525,0,0,1,87.5,45m0-44A21.5,21.5,0,1,0,109,22.5,21.525,21.525,0,0,0,87.5,1M78.4,31.405,77.6,30.8,81.286,26H78V19h4.338L87,15.62v2.94L91.5,12.7l.793.609L87,20.2v9.179L82.472,26.1Zm4.685-6.1L86,27.42V21.5ZM79,25h3V20H79Zm4-5.245v4.013l3-3.906V17.58Zm9.845,9.206-.691-.722a7.83,7.83,0,0,0,2.822-5.8,8.913,8.913,0,0,0-2.806-5.767l.66-.75a9.808,9.808,0,0,1,3.146,6.5,8.776,8.776,0,0,1-3.131,6.54m-2.483-2.616-.725-.689a4.652,4.652,0,0,0,0-6.412l.725-.688a5.651,5.651,0,0,1,0,7.789" transform="translate(-64 1)" fill="currentColor"/>
	</symbol>

	<symbol viewBox="0 0 48 48" id="audio-on">
		<path d="M22.5,45A22.5,22.5,0,1,1,45,22.5,22.525,22.525,0,0,1,22.5,45m0-44A21.5,21.5,0,1,0,44,22.5,21.525,21.525,0,0,0,22.5,1M22,29.381,17.338,26H13V19h4.338L22,15.62Zm-4-4.136,3,2.175V17.58l-3,2.175ZM14,25h3V20H14Zm13.845,3.961-.691-.722a7.83,7.83,0,0,0,2.822-5.8,8.913,8.913,0,0,0-2.806-5.767l.66-.75a9.808,9.808,0,0,1,3.146,6.5,8.776,8.776,0,0,1-3.131,6.54m-2.483-2.616-.725-.689a4.652,4.652,0,0,0,0-6.412l.725-.688a5.651,5.651,0,0,1,0,7.789" transform="translate(1 1)" fill="currentColor"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="pause">
		<path d="M23.5,46A22.5,22.5,0,1,1,46,23.5,22.521,22.521,0,0,1,23.5,46Zm0-44A21.5,21.5,0,1,0,45,23.5,21.531,21.531,0,0,0,23.5,2ZM27,31H26V16h1Zm-6,0H20V16h1Z" fill="currentColor"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="language-black">
		<path d="M32.5,23.9c0,11.9-3.8,21.6-8.5,21.6S15.5,35.9,15.5,24,19.3,2.5,24,2.5,32.5,12.1,32.5,23.9ZM5.3,34.5h0Zm-2.8-10h0Zm2.3-10h0Zm40.7,9.4A21.5,21.5,0,1,1,23.9,2.5H24A21.575,21.575,0,0,1,45.5,23.9Z" fill="none" stroke-width="1" stroke="#000" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 24 24" id="language-white">
		<path d="m12.5,23.5c2.76,0,5-4.92,5-11S15.26,1.5,12.5,1.5m0,22c-2.76,0-5-4.92-5-11S9.74,1.5,12.5,1.5m0,22c6.08,0,11-4.92,11-11m-11,11c-6.08,0-11-4.92-11-11M12.5,1.5C6.42,1.5,1.5,6.42,1.5,12.5M12.5,1.5c6.08,0,11,4.92,11,11M2.7,17.5h19.6M1.5,12.5h22M2.7,7.5h19.6" fill="none" stroke-width="1" stroke="#fff" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="info-circle">
		<path d="M23.5,20v0Zm0-2v0Zm0,27.5a22,22,0,1,0-22-22A22,22,0,0,0,23.5,45.5Z" fill="none" stroke="#000" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="add-circle">
		<path d="M23.5,17v6.5m0,0V30m0-6.5H30m-6.5,0H17m6.5,22a22,22,0,1,0-22-22A22,22,0,0,0,23.5,45.5Z" fill="none" stroke="#000" stroke-width="1"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="back">
		<path d="M28.6,14.4l-9.1,9.1,9.1,9.1" transform="translate(0 1.1)" fill="none" stroke="#000" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="forward">
		<path d="M19.5,32.5l9-9-9-9" transform="translate(-0.5 0.5)" fill="none" stroke="#000" stroke-width="1"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="arrow-down">
		<path d="M15.5,19.5l9,9,9-9" transform="translate(-1)" fill="none" stroke="#000" stroke-width="1"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="cancel-circle">
		<circle cx="24" cy="24" r="23" fill="none" stroke="#000" stroke-width="1"/>
		<path d="M31.6,15.4,15.4,31.6m0-16.2L31.7,31.7" transform="translate(1.1 1.1)" fill="none" stroke="#000" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 48 48" id="cancel">
		<path d="M31.6,15.4,15.4,31.6m0-16.2L31.7,31.7" transform="translate(1.1 1.1)" fill="none" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	
	<!---------------------------------------------- 24 x 24 icons ------------------------------------------------------>
	
	<symbol viewBox="0 0 24 24" id="system-info">
		<path d="M11.5,17V10m0-2V6m0,16.5a11,11,0,1,0-11-11A11,11,0,0,0,11.5,22.5Z" transform="translate(1 1)" fill="none" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 24 24" id="time">
		<path d="M12,4H11v8h5V11H12V4Zm-.5-4A11.566,11.566,0,0,0,0,11.5,11.566,11.566,0,0,0,11.5,23,11.566,11.566,0,0,0,23,11.5,11.566,11.566,0,0,0,11.5,0Zm0,22A10.5,10.5,0,1,1,22,11.5,10.5,10.5,0,0,1,11.5,22Z" fill="none" stroke="#000" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	
	<symbol viewBox="0 0 24 24" id="menu">
		 <g id="Group_4816" data-name="Group 4816" transform="translate(8141 3700)">
   			<rect id="Rectangle_1914" data-name="Rectangle 1914" width="22" height="1" transform="translate(-8140 -3683)" fill="#1a1a1a"/>
    		<rect id="Rectangle_1913" data-name="Rectangle 1913" width="22" height="1" transform="translate(-8140 -3689)" fill="#1a1a1a"/>
    		<rect id="Rectangle_1912" data-name="Rectangle 1912" width="22" height="1" transform="translate(-8140 -3695)" fill="#1a1a1a"/>
  		</g>
	</symbol>
	
	<symbol viewBox="0 0 24 24" id="search">
		<path d="M22,22l-8.3-8.3ZM15.5,9a6.277,6.277,0,0,1-6.1,6.5H9A6.5,6.5,0,1,1,15.5,9Z" fill="none" stroke="#000" stroke-width="1" fill-rule="evenodd"/>
	</symbol>
	<!---------------------------------------------- 12 x 12 icons ------------------------------------------------------>
	

	<symbol viewBox="0 0 12 12" id="forward-xsmall">
		<path d="M10.243,7.293,6,3.05l-.707.707L9.536,8,5.293,12.243,6,12.95l4.243-4.243L10.95,8Z" transform="translate(-2.293 -2.05)"/>
	</symbol>
	
	<!---------------------------------------------- brand icon ------------------------------------------------------>
	<symbol viewBox="0 0 305.007 102.045" id="brand-icon">
	    <path id="Audi_Rings_Medium_" d="M253.984,0a50.8,50.8,0,0,0-33.827,12.87,50.89,50.89,0,0,0-67.654,0,50.888,50.888,0,0,0-67.653,0,51.023,51.023,0,1,0,0,76.305,50.888,50.888,0,0,0,67.653,0,50.89,50.89,0,0,0,67.654,0A51.009,51.009,0,1,0,253.984,0M220.157,79.519a44.141,44.141,0,0,1,0-56.991,44.138,44.138,0,0,1,0,56.991m-67.654,0a44.141,44.141,0,0,1,0-56.991,44.138,44.138,0,0,1,0,56.991m-67.653,0a44.138,44.138,0,0,1,0-56.991,44.141,44.141,0,0,1,0,56.991M6.765,51.024A44.222,44.222,0,0,1,80.093,17.695a50.87,50.87,0,0,0,0,66.658A44.223,44.223,0,0,1,6.765,51.024M89.607,84.353a50.87,50.87,0,0,0,0-66.658,44.125,44.125,0,0,1,58.139,0,50.87,50.87,0,0,0,0,66.658,44.128,44.128,0,0,1-58.139,0m67.654,0a50.87,50.87,0,0,0,0-66.658,44.125,44.125,0,0,1,58.139,0,50.87,50.87,0,0,0,0,66.658,44.128,44.128,0,0,1-58.139,0m96.723,10.928a44.064,44.064,0,0,1-29.07-10.928,50.87,50.87,0,0,0,0-66.658,44.244,44.244,0,1,1,29.07,77.586" fill="white" transform="translate(0 -0.001)"/>
	</symbol>
	
	
     <!---------------------------------------------- audi icons ------------------------------------------------------>

      <symbol viewBox="0 0 48 48" id="motor">
			<path d="M45.5,15c0.9,0,1.5-0.6,1.5-1.5v-3C47,9.6,46.4,9,45.5,9h-12C32.6,9,32,9.6,32,10.5v3c0,0.9,0.6,1.5,1.5,1.5
			H39v3h-2v5H24l-11-1.9V18H9v-3h5.5c0.9,0,1.5-0.6,1.5-1.5v-3C16,9.6,15.4,9,14.5,9h-12C1.6,9,1,9.6,1,10.5v3C1,14.4,1.6,15,2.5,15
			H8v3H4v11h4v3H2.5C1.6,32,1,32.6,1,33.5v3C1,37.4,1.6,38,2.5,38h12c0.9,0,1.5-0.6,1.5-1.5v-3c0-0.9-0.6-1.5-1.5-1.5H9v-3h4v-3.1
			L24,24h13v5h2v3h-5.5c-0.9,0-1.5,0.6-1.5,1.5v3c0,0.9,0.6,1.5,1.5,1.5h12c0.9,0,1.5-0.6,1.5-1.5v-3c0-0.9-0.6-1.5-1.5-1.5H40v-3h2
			V18h-2v-3H45.5L45.5,15z M2.5,14C2.2,14,2,13.8,2,13.5v-3C2,10.2,2.2,10,2.5,10h12c0.3,0,0.5,0.2,0.5,0.5v3c0,0.3-0.2,0.5-0.5,0.5
			H2.5L2.5,14z M14.5,33c0.3,0,0.5,0.2,0.5,0.5v3c0,0.3-0.2,0.5-0.5,0.5h-12C2.2,37,2,36.8,2,36.5v-3C2,33.2,2.2,33,2.5,33H14.5
			L14.5,33z M12,25.1V28H5v-9h7v2.9l9.1,1.6L12,25.1L12,25.1z M45.5,33c0.3,0,0.5,0.2,0.5,0.5v3c0,0.3-0.2,0.5-0.5,0.5h-12
			c-0.3,0-0.5-0.2-0.5-0.5v-3c0-0.3,0.2-0.5,0.5-0.5H45.5L45.5,33z M41,19v9h-3v-9H41L41,19z M33.5,14c-0.3,0-0.5-0.2-0.5-0.5v-3
			c0-0.3,0.2-0.5,0.5-0.5h12c0.3,0,0.5,0.2,0.5,0.5v3c0,0.3-0.2,0.5-0.5,0.5H33.5L33.5,14z" fill="#1A1A18"/>
	  </symbol>
      <symbol viewBox="0 0 48 48" id="efficiency">
           <path d="M40.2,43H3V33h37.2l4.4,5L40.2,43z M4,42h35.8l3.6-4l-3.6-4H4V42z M28.2,29H3V19h25.2l4.4,5L28.2,29z M4,28h23.8l3.6-4
			l-3.6-4H4V28z M16.2,15H3V5h13.2l4.4,5L16.2,15z M4,14h11.8l3.6-4l-3.6-4H4V14z" fill="#1A1A18"/>
	  </symbol>
      <symbol viewBox="0 0 48 48" id="emission">
           <path d="M24.9,19c-3.2,0-5,2.1-5,4.9c0,2.9,1.7,4.9,5,4.9c3.2,0,5-2.1,5-4.9C30,21,28.2,19,24.9,19L24.9,19z
			 M24.9,27.7c-2.5,0-3.7-1.7-3.7-3.8c0-2.1,1.2-3.8,3.7-3.8s3.7,1.7,3.7,3.8C28.6,26,27.4,27.7,24.9,27.7L24.9,27.7z M12.4,23.9
			c0-2.5,1.5-3.7,3.9-3.7c0.9,0,1.8,0.1,2.8,0.5v-1.2c-0.8-0.4-1.9-0.5-3-0.5c-2.9,0-5.1,1.8-5.1,4.9c0,3.2,2.2,4.9,5.2,4.9
			c1.4,0,2.4-0.2,3.1-0.5v-1.1c-0.5,0.2-1.5,0.5-3,0.5C14.3,27.6,12.4,26.6,12.4,23.9L12.4,23.9z M33.9,29.4c1.2-0.8,2.3-1.5,2.3-2.7
			c0-1.2-1-2-2.7-2c-0.9,0-1.6,0.2-2.1,0.4v1c0.6-0.2,1.3-0.4,2.1-0.4c1,0,1.4,0.4,1.4,1c0,0.6-0.6,1.1-1.8,1.9l-2,1.5v0.8h5.6V30
			h-3.8L33.9,29.4L33.9,29.4z M36,12h-3.9c-0.5-1.2-2.8-5-8.9-5c-6.5,0-9.9,5.7-10.6,7.1c-2.3-0.1-8-0.1-8.7,5.1
			C3.5,22,4.7,24.8,7,26.5c-1.1,0.8-1.8,2.4-1.9,4.3c-0.2,2.6,0.8,5.9,3.4,7.4c1.5,0.9,4.1,1.4,8-0.9c1.6,2.3,4.2,3.7,6.9,3.7
			c2.7,0,5.4-1.6,6.8-4h3c6.8,0,6.8-5.4,6.8-7.7v-2.4c1.1-0.4,3.9-1.9,3.9-7.3C43.9,14.7,41.1,12,36,12L36,12z M39.4,26L39,26.1v3.2
			c0,3.2-0.4,6.7-5.8,6.7h-3.6l-0.1,0.3c-1.2,2.3-3.6,3.7-6.1,3.7c-2.6,0-4.9-1.4-6.4-3.7l-0.3-0.4l-0.4,0.3
			c-2.9,1.9-5.5,2.3-7.4,1.1C6.7,36,5.9,33,6,30.8c0.1-1.9,0.9-3.4,2.1-3.9L9,26.6l-0.8-0.5c-2.3-1.4-3.6-4-3.3-6.7
			c0.6-4.1,4.5-4.4,7.9-4.3l0.4,0l0.1-0.3c0-0.1,3.3-6.8,9.8-6.8c6.5,0,8.1,4.5,8.1,4.7l0.1,0.3H36c4.6,0,6.9,2.2,6.9,6.6
			C42.9,25.2,39.5,26,39.4,26L39.4,26z" fill="#1A1A18"/>
      </symbol>
      <symbol viewBox="0 0 48 48" id="gear">
			<polygon points="37,13 37,24 29,24 29,13 28,13 28,24 20,24 20,13 19,13 19,24 11,24 11,13 10,13 10,25 19,25 19,36 
			20,36 20,25 28,25 28,36 29,36 29,25 37,25 37,36 38,36 38,13 37,13 " fill="#1A1A18"/>
      </symbol>
      <symbol viewBox="0 0 48 48" id="fuel">
            <path d="M10,21h16v-1H10V21L10,21z M36.9,3.7l-0.8,0.6c3.9,5,5.9,9.1,5.9,12.2v19c0,1.9-1.6,3.5-3.5,3.5
			c-1.9,0-3.5-1.6-3.5-3.5v-11c0-2.5-2-4.5-4.5-4.5H29V6.5C29,4.6,27.4,3,25.5,3h-15C8.6,3,7,4.6,7,6.5V44H5v1h26v-1h-2V21h1.5
			c1.9,0,3.5,1.6,3.5,3.5v11c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5v-19C43,13.2,41,9,36.9,3.7L36.9,3.7z M28,44H8V6.5
			C8,5.1,9.1,4,10.5,4h15C26.9,4,28,5.1,28,6.5V44L28,44z" fill="#1A1A18"/>
	  </symbol>
      <symbol viewBox="0 0 48 48" id="power">
            <path d="M23.5,7C13.3,7,5,15.3,5,25.5c0,4.8,2,9.6,5.4,13.1l0.4,0.4l3.6-3.6l-0.7-0.7l-2.8,2.8C7.9,34.4,6.1,30.2,6,26
			h4v-1H6c0.1-4.4,1.9-8.5,4.8-11.5l2.9,2.9l0.7-0.7l-2.9-2.9c3-2.9,7.1-4.7,11.5-4.8v4h1V8c4.4,0.1,8.5,1.9,11.5,4.8l-2.9,2.9
			l0.7,0.7l2.9-2.9c2.9,3,4.7,7.1,4.8,11.5h-4v1h4c-0.1,4.4-1.8,8.4-4.8,11.5l-2.8-2.8l-0.7,0.7l3.5,3.5l0.4-0.3
			c3.5-3.4,5.5-8,5.5-13.1C42,15.3,33.7,7,23.5,7L23.5,7z M35,29.1l-8-2.9c0-0.2,0.1-0.5,0.1-0.7c0-1.9-1.6-3.5-3.5-3.5
			c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5c1.3,0,2.5-0.8,3.1-1.9l8,2.9L35,29.1L35,29.1z M23.5,28c-1.4,0-2.5-1.1-2.5-2.5
			c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C26,26.9,24.9,28,23.5,28L23.5,28z" fill="#1A1A18"/>
      </symbol>
      <symbol viewBox="0 0 48 48" id="consumption">
          <path d="M32.6,10.2l-0.4-0.6l-0.4,0.6c0,0-1.5,2.2-3.2,5l0.9,0.5c1.2-1.9,2.2-3.5,2.8-4.4c1.6,2.4,7.3,11,7.3,14.5
			c-0.1,3.6-2.6,6.6-6,7.2l0.2,1c3.9-0.7,6.7-4.1,6.8-8.2C40.5,21.5,32.9,10.7,32.6,10.2L32.6,10.2z M19.5,4.6l-0.4,0.6
			C18.6,5.9,7,22.4,7,29.1c0,7,5.7,12.9,12.5,12.9C26.3,42,32,36.1,32,29.1c0-6.6-11.6-23.2-12.1-23.9L19.5,4.6L19.5,4.6z M19.5,41
			C13.3,41,8,35.5,8,29.1c0-5.7,9.4-19.7,11.5-22.7C21.6,9.4,31,23.5,31,29.1C31,35.4,25.6,41,19.5,41L19.5,41z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="qr-code" viewBox="0 0 48 48">
	      <g>
				<path class="st0" d="M9,18h9V9H9V18L9,18z M10,10h7v7h-7V10L10,10z M18.5,26H5v17h17V29.5C22,27.5,20.5,26,18.5,26L18.5,26z M21,42
				H6V27h12.5c1.4,0,2.5,1.1,2.5,2.5V42L21,42z M9,39h9v-9H9V39L9,39z M10,31h7v7h-7V31L10,31z M36,43h7v-7h-7V43L36,43z M37,37h5v5
				h-5V37L37,37z M39,9h-9v9h9V9L39,9z M38,17h-7v-7h7V17L38,17z M36,33h7v-7h-7V33L36,33z M37,27h5v5h-5V27L37,27z M26,5v13.5
				c0,2,1.5,3.5,3.5,3.5H43V5H26L26,5z M42,21H29.5c-1.4,0-2.5-1.1-2.5-2.5V6h15V21L42,21z M26,43h7v-7h-7V43L26,43z M27,37h5v5h-5V37
				L27,37z M26,33h7v-7h-7V33L26,33z M27,27h5v5h-5V27L27,27z M5,22h13.5c2,0,3.5-1.5,3.5-3.5V5H5V22L5,22z M6,6h15v12.5
				c0,1.4-1.1,2.5-2.5,2.5H6V6L6,6z"/>
		  </g>
      </symbol>
      <symbol id="maximum-speed" viewBox="0 0 48 48">
        <path d="M2,25h9v-1H2V25L2,25z M4,28h7v-1H4V28L4,28z M45.7,24.6c-0.3-0.9-1.3-2-3.1-2.6c-1-0.3-2.7-0.7-5.2-1
		c-2.1-2-3.5-3.1-4.3-3.5c-0.8-0.3-2.4-0.5-4.7-0.5c-1.7,0-4,0.1-5.2,0.5c-0.9,0.3-2,0.9-3,1.4c-1.3,0.7-2.6,1.4-3.4,1.5
		c-0.5,0.1-1.1,0.2-1.6,0.3c-1,0.3-1.8,1.2-1.9,2.2L13,26.7c-0.1,1.1,0.9,2,2,2.1l1.5,0.1c0.2,1.7,1.7,3,3.5,3c1.8,0,3.2-1.3,3.5-3
		h12.1c0.2,1.7,1.7,3,3.5,3c1.8,0,3.2-1.3,3.5-3h1.8c1,0,1.7-0.8,1.7-1.7V27C46,25.9,45.9,25.1,45.7,24.6L45.7,24.6z M45,27.3
		c0,0.4-0.3,0.8-0.8,0.8h-2.8v0.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V28h-14v0.5c0,1.4-1.1,2.5-2.5,2.5
		c-1.4,0-2.5-1.1-2.5-2.5V28l-2.4-0.2c-0.6,0-1.1-0.6-1.1-1l0.4-3.6c0-0.6,0.5-1.2,1.1-1.4c0.5-0.1,1-0.2,1.6-0.3
		c0.9-0.2,2.3-0.9,3.7-1.6c1-0.5,2-1.1,2.9-1.4c0.8-0.3,2.6-0.5,4.8-0.5c2.1,0,3.6,0.2,4.3,0.5c0.5,0.2,1.6,1,4.1,3.4L37,22l0.2,0
		c2.5,0.3,4.2,0.7,5.2,1c1.5,0.5,2.3,1.4,2.4,1.9c0.1,0.4,0.2,1.1,0.2,2.1V27.3L45,27.3z M4,22h7v-1H4V22L4,22z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="mileage" viewBox="0 0 48 48">
          <path d="M5.1,28.8l0.9,0.5L11.3,19H4v1h5.7L5.1,28.8L5.1,28.8z M14,11H1v26h13V11L14,11z M13,36H2V12h11V36L13,36z
			 M1,41h45v-1H1V41L1,41z M26.2,28.3c0.5-0.5,0.8-1.1,0.8-1.8c0-0.8-0.3-1.5-0.9-2.1C25,23.3,22.9,23.1,22,23v-3h5v-1h-6v5l0.5,0
			c0.7,0,2.9,0.2,3.9,1.2c0.4,0.4,0.6,0.8,0.6,1.3c0,0.4-0.2,0.8-0.5,1c-1.1,0.9-4,0.7-5,0.5l-0.2,1c0.1,0,1.1,0.2,2.3,0.2
			C23.9,29.2,25.3,29,26.2,28.3L26.2,28.3z M1,7v1h45V7H1L1,7z M33,37h13V11H33V37L33,37z M41,33h-3.5l3.5-4.9V33L41,33z M34,12h7
			c-0.1,0.6-0.3,1.1-0.8,1.4c-0.4,0.4-0.9,0.6-1.5,0.6c-0.1,0-0.2,0-0.2,0l0,1c0.1,0,0.2,0,0.3,0c2.2,0.1,2.3,1.8,2.3,2
			c0,0.1,0,2-1.5,2c-0.9,0.1-1.9-0.2-2.6-0.9l-0.7,0.8c0.9,0.8,2.2,1.2,3.3,1.1c2,0,2.5-2,2.5-3c0-0.7-0.4-1.9-1.5-2.5
			c0.1-0.1,0.3-0.2,0.4-0.3c0.6-0.5,1-1.3,1.1-2.2h3v24h-3v-2h1v-1h-1v-8.1L35.5,34H41v2h-7V12L34,12z M17,11v26h13V11H17L17,11z
			 M29,36H18V12h11V36L29,36z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="guarantee" viewBox="0 0 48 48">
          <path d="M33.9,27.7c2.2-2.4,3.6-5.6,3.6-9.2C37.5,11.1,31.4,5,24,5c-7.4,0-13.5,6.1-13.5,13.5c0,3.5,1.4,6.7,3.6,9.2
			L9.9,42.1l5.9-2.4l3.7,5.1L23.3,32c0.5,0,1,0,1.5,0l3.7,12.9l3.7-5.1l5.9,2.4L33.9,27.7L33.9,27.7z M19.2,42.6l-3-4.1l-4.7,1.9
			l3.4-12c2,1.8,4.5,3.1,7.3,3.4L19.2,42.6L19.2,42.6z M24,31c-6.9,0-12.5-5.6-12.5-12.5C11.5,11.6,17.1,6,24,6
			c6.9,0,12.5,5.6,12.5,12.5C36.5,25.4,30.9,31,24,31L24,31z M31.8,38.6l-3,4.1l-3.1-10.8c2.7-0.4,5.3-1.6,7.3-3.4l3.4,12L31.8,38.6
			L31.8,38.6z M22.3,23.1l-3.9-3.8L17.7,20l4.7,4.6l8.4-10.7L30,13.2L22.3,23.1L22.3,23.1z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="first-registration" viewBox="0 0 48 48">
          <path d="M24,12c-6.1,0-11,4.9-11,11c0,6.1,4.9,11,11,11c6.1,0,11-4.9,11-11C35,16.9,30.1,12,24,12L24,12z M14,23
			c0-0.3,0-0.7,0-1h6.1C20,22.7,20,23.5,20,24.2c0,1,0.2,1.8,0.6,2.4l-3.5,3.5C15.2,28.4,14,25.8,14,23L14,23z M24,33
			c-2.3,0-4.5-0.8-6.2-2.1l3.4-3.4c0.7,0.7,1.6,1,2.8,1c1.1,0,2.1-0.4,2.8-1l3.4,3.4C28.5,32.2,26.3,33,24,33L24,33z M21,24.2
			c0-1.2,0-2.5,0.8-3C22.1,21.1,23,21,24,21c1,0,1.9,0.1,2.2,0.3c0.8,0.5,0.8,1.8,0.8,3c0,2-1.1,3.3-3,3.3C22.1,27.5,21,26.3,21,24.2
			L21,24.2z M34,23c0,2.8-1.2,5.4-3.1,7.2l-3.5-3.5c0.4-0.7,0.6-1.5,0.6-2.4c0-0.7,0-1.5-0.2-2.2H34C34,22.3,34,22.7,34,23L34,23z
			 M27.4,21c-0.2-0.2-0.4-0.4-0.6-0.6c-1-0.6-4.6-0.6-5.5,0c-0.2,0.2-0.4,0.4-0.6,0.6h-6.4c0.9-4.6,5-8,9.8-8c4.8,0,8.9,3.4,9.8,8
			H27.4L27.4,21z M37.5,2H7v6H5v1h2v30H5v1h2v6h30.5c1.9,0,3.5-1.6,3.5-3.5v-37C41,3.6,39.4,2,37.5,2L37.5,2z M40,42.5
			c0,1.4-1.1,2.5-2.5,2.5H8v-5h1.9v-1H8V9h1.9V8H8V3h29.5C38.9,3,40,4.1,40,5.5V42.5L40,42.5z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="next-inspection" viewBox="0 0 48 48">
          <path d="M38.5,45h-29C7.6,45,6,43.4,6,41.5V4h6v1H7v36.5C7,42.9,8.1,44,9.5,44h29c1.4,0,2.5-1.1,2.5-2.5V5h-5V4h6v37.5
			C42,43.4,40.4,45,38.5,45z M33,2H15v6h18V2z M16,3h16v4H16V3z M34.4,17l-0.8-0.6L20.9,33.2l-6.6-6.6l-0.7,0.7l7.4,7.4L34.4,17z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="calendar" viewBox="0 0 48 48">
          <path d="M24.7,24c-1.5,0-2.9-0.2-4.3-0.6v0.9c1.4,0.4,2.8,0.6,4.3,0.6c3.8,0,5.9-1.3,5.9-3.7c0-2.4-2.8-3.7-8.3-4h-0.4
			l0.2-4.4h7.7V12h-8.7l-0.2,6c5.6,0.1,8.4,1.2,8.4,3.2C29.3,22.5,28.5,24,24.7,24L24.7,24z M38,2H3v1h35V2L38,2z M44,13v30.6
			c0,0.9-0.7,1.4-1.4,1.4H14.4c-0.9,0-1.4-0.7-1.4-1.4V39h14.8L38,28.8V7h-1v21H27v10H5.4C4.5,38,4,37.3,4,36.6V7H3v29.6
			C3,37.9,4.1,39,5.4,39H12v4.6c0,1.3,1.1,2.4,2.4,2.4h28.2c1.3,0,2.4-1.1,2.4-2.4V13H44L44,13z M28,29h8.4L28,37.4V29L28,29z
			 M9.1,23.8v0.8h8.5v-0.8H14v-12h-1v0.1l-3.9,0.9v0.8l3.6-0.8v11H9.1L9.1,23.8z" fill="#1A1A18"/>
 	  </symbol>
      <symbol id="acceleration" viewBox="0 0 48 48">
          <path d="M27,25.5c0-1.9-1.6-3.5-3.5-3.5c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5c1.3,0,2.5-0.8,3.1-1.9l8,2.9
			l0.3-0.9l-8-2.9C27,26,27,25.7,27,25.5L27,25.5z M23.5,28c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
			C26,26.9,24.9,28,23.5,28L23.5,28z M38.3,14.4l3-3l-3.5-3.5l-2.9,2.9c-2.5-1.9-5.5-3.2-8.8-3.7V5h5V1H16v4h5v2.2
			c-3.2,0.4-6.2,1.7-8.6,3.6l-3-3l-3.5,3.5l2.9,2.9C6.4,17.4,5,21.3,5,25.5C5,35.7,13.3,44,23.5,44S42,35.7,42,25.5
			C42,21.3,40.6,17.5,38.3,14.4L38.3,14.4z M37.7,9.3l2.1,2.1l-2.2,2.2c-0.6-0.8-1.3-1.5-2.1-2.1L37.7,9.3L37.7,9.3z M17,4V2h13v2H17
			L17,4z M25,5v2.1C24.5,7,24,7,23.5,7c-0.5,0-1,0-1.5,0.1V5H25L25,5z M7.3,11.3l2.1-2.1l2.2,2.2c-0.8,0.6-1.5,1.3-2.1,2.1L7.3,11.3
			L7.3,11.3z M23.5,43C13.9,43,6,35.1,6,25.5C6,15.9,13.9,8,23.5,8C33.1,8,41,15.9,41,25.5C41,35.1,33.1,43,23.5,43L23.5,43z" fill="#1A1A18"/>
      </symbol>
      <symbol id="electric-range" viewBox="0 0 48 48">
          <path d="M44,43H18v-4h-1v9h1v-4h26v4h1v-9h-1V43L44,43z M23,20H7v1h16V20L23,20z M43,9V4.5h-1V9h-5V4.5h-1V9h-3v6.5
			c0,3.4,2.6,6.2,6,6.5v13.5c0,1.9-1.6,3.5-3.5,3.5S32,37.4,32,35.5v-11c0-2.5-2-4.5-4.5-4.5H26V6.5C26,4.6,24.4,3,22.5,3h-15
			C5.6,3,4,4.6,4,6.5V44H2v1h13v-1H5V6.5C5,5.1,6.1,4,7.5,4h15C23.9,4,25,5.1,25,6.5V38h1V21h1.5c1.9,0,3.5,1.6,3.5,3.5v11
			c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5V22c3.4-0.3,6-3.1,6-6.5V9H43L43,9z M45,15.5c0,3-2.5,5.5-5.5,5.5S34,18.5,34,15.5V10h11
			V15.5L45,15.5z" fill="#1A1A18"/>
      </symbol>
      <symbol id="consumption-electric" viewBox="0 0 48 48">
          <path d="M44,13h-5v-3h-6v3H15v-3H9v3H4v25h40V13L44,13z M34,11h4v2h-4V11L34,11z M10,11h4v2h-4V11L10,11z M43,37H5V14h38V37L43,37z
			M16,23H9v1h7V23L16,23z M35,27h1v-3h3v-1h-3v-3h-1v3h-3v1h3V27L35,27z" fill="#1A1A18"/>
      </symbol>
      <symbol id="fuel-electric" viewBox="0 0 48 48">
          <path d="M8,21h16v-1H8V21L8,21z M44,9V4.5h-1V9h-5V4.5h-1V9h-3v6.5c0,3.4,2.6,6.2,6,6.5v13.5c0,1.9-1.6,3.5-3.5,3.5S33,37.4,33,35.5
			v-11c0-2.5-2-4.5-4.5-4.5H27V6.5C27,4.6,25.4,3,23.5,3h-15C6.6,3,5,4.6,5,6.5V44H3v1h26v-1h-2V21h1.5c1.9,0,3.5,1.6,3.5,3.5v11
			c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5V22c3.4-0.3,6-3.1,6-6.5V9H44L44,9z M26,44H6V6.5C6,5.1,7.1,4,8.5,4h15
			C24.9,4,26,5.1,26,6.5V44L26,44z M46,15.5c0,3-2.5,5.5-5.5,5.5S35,18.5,35,15.5V10h11V15.5L46,15.5z" fill="#1A1A18"/>
	  </symbol>
      <symbol id="fuel-gas" viewBox="0 0 24 24">
          <path id="Shape" d="M21.75,2.83H12.88a8.81,8.81,0,0,0-8.8,8.79v.76a8.73,8.73,0,0,0,2.13,5.71l-.8.81a4.89,4.89,0,0,1-3.47,1.43H1v1.34h.94a6.19,6.19,0,0,0,4.41-1.83l.81-.8a8.74,8.74,0,0,0,5.72,2.13h.74a8.81,8.81,0,0,0,8.8-8.79V3.5Zm-.67,9.55a7.46,7.46,0,0,1-7.46,7.45h-.74A7.42,7.42,0,0,1,8.1,18.1L11,15.17h5.5V13.83H12.36l5.36-5.36-.94-.94-5.61,5.61V8.5H9.83v6L7.15,17.15a7.42,7.42,0,0,1-1.73-4.77v-.76a7.46,7.46,0,0,1,7.46-7.45h8.2Z" transform="translate(1 0.17)" fill="#1A1A18" stroke="white" stroke-width="0.8"/>
      </symbol>


      <!---------------------------------------------- general icons ------------------------------------------------------>

      <symbol id="info-icon" viewBox="0 0 24 24">
          <path d="M11,17h1v-7h-1V17L11,17z M11.5,0C5.2,0,0,5.2,0,11.5C0,17.8,5.2,23,11.5,23C17.8,23,23,17.8,23,11.5
			C23,5.2,17.8,0,11.5,0L11.5,0z M11.5,22C5.7,22,1,17.3,1,11.5C1,5.7,5.7,1,11.5,1C17.3,1,22,5.7,22,11.5C22,17.3,17.3,22,11.5,22
			L11.5,22z M11,8h1V6h-1V8L11,8z" fill="#333333" stroke='none'/>
	  </symbol>
      <symbol id="play-icon" viewBox="0 0 24 24">
          <path d="M11.5,0C5.2,0,0,5.2,0,11.5C0,17.8,5.2,23,11.5,23C17.8,23,23,17.8,23,11.5C23,5.2,17.8,0,11.5,0L11.5,0z
			 M11.5,22C5.7,22,1,17.3,1,11.5C1,5.7,5.7,1,11.5,1C17.3,1,22,5.7,22,11.5C22,17.3,17.3,22,11.5,22L11.5,22z M9,16.7l7.4-5.2L9,6.5
			V16.7L9,16.7z M10,8.3l4.6,3.2L10,14.7V8.3L10,8.3z" stroke='none'/>
      </symbol>
      <symbol id="pause-icon" viewBox="0 0 24 24">
          <path d="M9,16h1V7H9V16L9,16z M13,16h1V7h-1V16L13,16z M11.5,0C5.2,0,0,5.2,0,11.5C0,17.8,5.2,23,11.5,23
			C17.8,23,23,17.8,23,11.5C23,5.2,17.8,0,11.5,0L11.5,0z M11.5,22C5.7,22,1,17.3,1,11.5C1,5.7,5.7,1,11.5,1C17.3,1,22,5.7,22,11.5
			C22,17.3,17.3,22,11.5,22L11.5,22z"/>
      </symbol>
      <symbol id="volume-on-icon" viewBox="0 0 24 24">
          <path d="M5,9v5h3.4l3.6,2.3V6.6L8.3,9H5L5,9z M8,13H6v-3h2V13L8,13z M9,9.8l2-1.3v6.1l-2-1.3V9.8L9,9.8z M13.5,8.7
			l-0.5,0.9c0,0,1,0.6,1.1,1.6c0.1,0.7-0.3,1.6-1.2,2.4l0.7,0.7c1.1-1.1,1.6-2.2,1.5-3.3C14.8,9.5,13.5,8.7,13.5,8.7L13.5,8.7z
			 M15.8,7l-0.7,0.8c0,0,1.8,1.6,1.8,3.7c0,1.3-0.6,2.6-1.8,3.9l0.7,0.7c1.5-1.4,2.2-3,2.1-4.6C17.9,8.9,15.9,7.1,15.8,7L15.8,7z
			 M11.5,0C5.2,0,0,5.2,0,11.5C0,17.8,5.2,23,11.5,23C17.8,23,23,17.8,23,11.5C23,5.2,17.8,0,11.5,0L11.5,0z M11.5,22
			C5.7,22,1,17.3,1,11.5C1,5.7,5.7,1,11.5,1C17.3,1,22,5.7,22,11.5C22,17.3,17.3,22,11.5,22L11.5,22z"/>
      </symbol>
      <symbol id="volume-off-icon" viewBox="0 0 24 24">
          <path d="M11.5,0C5.2,0,0,5.2,0,11.5C0,17.8,5.2,23,11.5,23C17.8,23,23,17.8,23,11.5C23,5.2,17.8,0,11.5,0L11.5,0z
			 M1,11.5C1,5.7,5.7,1,11.5,1c2,0,4,0.6,5.6,1.6l-5.1,6V6.6L8.3,9H5v5h2.5l-3.8,4.5C2,16.7,1,14.2,1,11.5L1,11.5z M9,12.2V9.8l2-1.3
			v1.4L9,12.2L9,12.2z M11,11.4v3.1l-1.7-1.1L11,11.4L11,11.4z M8,10v3H6v-3H8L8,10z M11.5,22c-2.7,0-5.2-1-7.1-2.8l4.2-5.1l3.4,2.1
			v-6.1l5.9-7c2.5,1.9,4.1,4.9,4.1,8.3C22,17.3,17.3,22,11.5,22L11.5,22z M15.5,8.1c0.8,0.9,3,3.9-0.4,7.1l0.7,0.7
			c4.2-3.9,1.1-7.8,0.4-8.5L15.5,8.1L15.5,8.1z M13.7,10.3c0.4,0.7,0.6,1.8-0.8,3.3l0.7,0.7c2.1-2.1,1.4-3.9,1-4.5L13.7,10.3
			L13.7,10.3z"/>
      </symbol>
      <symbol id="picture-icon" viewBox="0 0 24 24">
          <path d="M0,2v20h24V2H0L0,2z M23,21H1.7l7.8-7.8l4.9,4.9l5-4.9l3.6,3.4V21L23,21z M23,15.2l-3.6-3.4l-5,4.9l-4.9-4.9
			L1,20.3V3h22V15.2L23,15.2z M5.5,11C6.9,11,8,9.9,8,8.5C8,7.1,6.9,6,5.5,6S3,7.1,3,8.5C3,9.9,4.1,11,5.5,11L5.5,11z M5.5,7
			C6.3,7,7,7.7,7,8.5C7,9.3,6.3,10,5.5,10C4.7,10,4,9.3,4,8.5C4,7.7,4.7,7,5.5,7L5.5,7z" fill="#333333" stroke='none'/>
      </symbol>
      <symbol id="search-icon"  viewBox="0 0 24 24">
          <path d="M14.4,13.7C15.5,12.4,16,10.7,16,9c0-3.9-3.1-7-7-7C5.1,2,2,5.1,2,9c0,3.9,3.1,7,7,7h0.4
			c1.6,0,3.1-0.6,4.2-1.6l8,8l0.7-0.7L14.4,13.7L14.4,13.7z M13.4,13.2c-1.1,1.1-2.5,1.8-4,1.8H9c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6
			s6,2.7,6,6v0C15,10.6,14.5,12.1,13.4,13.2L13.4,13.2z" fill="#333333"/>
	  </symbol>
      <symbol id="quit-icon" viewBox="0 0 24 24">
          <polygon points="17.5,6.3 16.7,5.5 11.5,10.8 6.3,5.5 5.5,6.3 10.8,11.5 5.5,16.7 6.3,17.5 11.5,12.2 16.7,17.5 
			17.5,16.7 12.2,11.5 17.5,6.3" fill="#333333"/>
	  </symbol>
      <symbol id="chevron-icon" viewBox="0 0 24 24">
          <path d="M12,17.813a1.655,1.655,0,0,1-1.189-.5l-9.786-10,.95-.93,9.785,10a.34.34,0,0,0,.48,0l9.784-10,.951.93-9.785,10A1.652,1.652,0,0,1,12,17.813Z" transform="translate(-0.025 -0.386)" fill="#333333"/>
      </symbol>
   </svg>
</div>`;
