import React, { useContext, useState } from 'react';
import './mediacenter.scss';
import { SspProviderContext } from '../../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { OverlayContentProps } from '../interfaces';
import { checkHtml } from '../../../did-utility/src/Functions/checkHTML';
import VideoPlayerAudi from '../../VideoPlayerAudi';
import clickLinks from '../../../did-utility/src/Functions/clickLinks';
import i18next from 'i18next';

export default function MediaCenter(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);

	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const mediaLib = contentModel
		.ref('salesgroupref')
		.asModel.ref('medialibrary');

	const videos = mediaLib.asModel
		.reflist('videos')
		.filter(item => item.asModel.bool('marketable', true));

	const [chosenVideo, setChosenVideo] = useState<SSPContentVO | null>(
		videos.length > 0 ? videos[0] : null
	);

	function getLength(video: SSPContentVO): string {
		const lengthInSeconds = parseInt(
			video.asAsset.fileProperties.fileMeta.target.length || '0',
			10
		);
		var date = new Date(0);
		date.setSeconds(lengthInSeconds);
		return date.toISOString().substr(14, 5);
	}

	return (
		<div className='media-center'>
			<div className='sticky-top'>
				<VideoPlayerAudi
					video={chosenVideo || undefined}
					autoPlay={chosenVideo !== null}
				/>
				{props.closeBtn()}
				<h1>{i18next.t('LinkMediaCenter')}</h1>
			</div>
			<div className='video-list'>
				{videos.length > 0 &&
					videos.map(video => (
						<div
							className={`video-list-element${
								video.uid === chosenVideo?.uid ? ' active' : ''
							}`}
							key={`video-${video.uid}`}
							onClick={() => {
								setChosenVideo(video);
							}}
						>
							<div className='truncate'>
								<h6 className='short bold'>
									{video.asModel.str('name')}
								</h6>
								<p
									onClick={e => clickLinks(e, store)}
									dangerouslySetInnerHTML={checkHtml(
										video.asModel.str('description'),
										i18next.t('ClickMe')
									)}
								/>
							</div>
							<p className='small'>
								<svg className='icon'>
									<use xlinkHref='#time' />
								</svg>
								{i18next.t(
									'MediaCenterVideoLengthDescription'
								) +
									': ' +
									getLength(video) +
									' ' +
									i18next.t('MediaCenterVideoLengthUnit')}
							</p>
							<div className='preview-img'>
								<img
									src={video.asAsset.posterUrl}
									alt={video.asModel.str('name')}
								/>
								<svg className='play-btn icon-48'>
									<use xlinkHref='#system-play' />
								</svg>
							</div>
						</div>
					))}
			</div>
		</div>
	);
}
