import React, { useContext } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './qrcode.scss';
import { SspProviderContext } from '../../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { OverlayContentProps } from '../interfaces';
import i18next from 'i18next';

export default function QRCode(props: OverlayContentProps): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;

	return (
		<div className='qr-code'>
			{props.closeBtn()}
			<div className='side-padding-overlay'>
				<h2 className='bold'>{i18next.t('ContentToGoHeadline')}</h2>
				{contentModel.has('onlineCode') && contentModel.str('onlineCode') !== '' && (
					<>
						<p className='long'>{i18next.t('AudiCodeHeadline')}</p>
						<p className='short'>
							{i18next.t('AudiCodeDescription')}
						</p>
						<div className='text-code-container'>
							<h4>{contentModel.str('onlineCode')}</h4>
						</div>
					</>
				)}
				{contentModel.has('weblink') && contentModel.str('weblink') !== '' && (
					<div className='qr-code-area'>
						<p className='long'>{i18next.t('WeblinkHeadline')}</p>
						<p className='short'>
							{i18next.t('WeblinkDescription')}
						</p>
						<div className='qr-code-container'>
							<QRCodeSVG
								value={contentModel.str('weblink')}
								size={294}
								bgColor={'#ffffff'}
								fgColor={'#000000'}
								level={'H'}
								includeMargin={false}
							/>
						</div>
					</div>
				)}
				{contentModel.has('weblink_dga') && contentModel.str('weblink_dga') !== '' && (
					<div className='dga-code-area'>
						<p className='long'>{i18next.t('DGAHeadline')}</p>
						<p className='short'>{i18next.t('DGADescription')}</p>
						<div className='dga-code-container'>
							<QRCodeSVG
								value={contentModel.str('weblink_dga')}
								size={294}
								bgColor={'#ffffff'}
								fgColor={'#000000'}
								level={'H'}
								includeMargin={false}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
