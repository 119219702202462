export function registerSW(password: string) {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('/service-worker.js', { scope: '/' })
			.then(registration => {
				console.log('service worker registered');
				const data = {
					type: 'CACHE_URLS',
					payload: Array.from(
						new Set(
							[
								'/',
								...performance
									.getEntriesByType('resource')
									.map(r => r.name)
									.filter(name =>
										name.includes(window.location.origin)
									),
								'/src/assets/fonts/AudiType-ExtendedNormal_4.03.ttf',
								'/src/assets/fonts/AudiType-ExtendedBold_4.03.ttf',
								'/src/assets/fonts/AudiType-WideBold_4.03.ttf',
							].map(url =>
								url.replace(window.location.origin, '')
							)
						)
					),
				};
				if (registration.installing) {
					registration.installing.postMessage(data);
					registration.installing.postMessage({
						type: 'AUTH',
						payload: window.btoa(`ssp:${password}`),
					});
				}
			})
			.catch(err =>
				console.log('service worker registration failed:', err)
			);
	}
}
