import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import replaceConsoleFunctions from './did-utility/src/Functions/replaceConsoleFunctions';

const params = new URLSearchParams(window.location.search);

if (
	!window.location.href.includes('localhost') &&
	params.get('log') === 'true'
) {
	replaceConsoleFunctions('did-audi');
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

if (params.get('iframe') === 'true') {
	let url = new URL(window.location.href);
	url.searchParams.delete('iframe');
	root.render(<iframe className='app' title='DID' src={url.toString()} />);
} else {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
}
