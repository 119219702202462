import './styles.scss';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import React, { useContext, useEffect, useState } from 'react';
import { BackToSetup, SspProviderContext } from '../../did-utility';
import BuildInfo from '../../did-utility/src/Components/BuildInfo';
import i18next from 'i18next';
import LanguageMap from '../../did-utility/src/Components/LaungugeMap';

export default function Footer() {
	const [languageOpen, setLanguageOpen] = useState<boolean>(false);
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const languages = Object.keys(i18next.options.resources as Object);
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const handleOnlineStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		window.addEventListener('online', handleOnlineStatusChange);
		window.addEventListener('offline', handleOnlineStatusChange);
	}, []);

	return (
		<>
			<div className='footer'>
				<div className='footer-content'>
					<button
						id='footer-language-btn'
						className='set-language-open'
						onClick={() => {
							setLanguageOpen(true);
						}}
						style={
							languages.length > 1 ? {} : { width: 0, padding: 0 }
						}
					>
						{languages.length > 1 && (
							<>
								<svg className='icon-24'>
									<use xlinkHref='#language-white' />
								</svg>
								<p
									className={`${
										languageOpen ? 'active ' : ''
									}language-letters`}
								>
									{i18next.t('ThisLanguage', {
										defaultValue: i18next.language,
									})}
								</p>
							</>
						)}
					</button>
					{languages.length > 1 && (
						<div
							className={`language-popup${
								languageOpen ? ' open' : ''
							}`}
						>
							<button
								onClick={() => {
									setLanguageOpen(false);
								}}
							>
								<svg className='icon-48'>
									<use xlinkHref='#cancel' />
								</svg>
							</button>
							<div className='languages'>
								<LanguageMap
									languages={languages}
									store={store}
									setLanguageOpen={setLanguageOpen}
								/>
							</div>
						</div>
					)}
					<p className='small'>
						{contentModel.str('vin') && (
							<>
								{i18next.t('SerialNumberAbbreviation') +
									': ' +
									contentModel.str('vin')}
							</>
						)}
						{contentModel.has('vin') &&
						contentModel.has('commissionnr')
							? ' | '
							: ''}
						{contentModel.str('commissionnr') && (
							<>
								{i18next.t('JobNumberAbbreviation') +
									': ' +
									contentModel.str('commissionnr')}
							</>
						)}
						{!isOnline && <div className='online-lamp'></div>}
						<BuildInfo local={i18next.language} />
					</p>
				</div>
			</div>
			<BackToSetup brand={'audi'} langBtnId={'footer-language-btn'} />
		</>
	);
}
