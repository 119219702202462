import React, { useContext, useState } from 'react';
import './styles.scss';
import { addOverlay, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import {
	UpperTableElement,
	UpperTableElementNedcWltp,
	UpperTableElementNumber,
} from './upperTableElement';
import i18next from 'i18next';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import { TeaserImage } from '../../did-utility/src/Components/TeaserImage';
import Disclaimers from '../../did-utility/src/Components/Disclaimers';
import { getCookie } from '../../did-utility/src/Functions/getCookie';

export default function Overview(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const highlightEquip = contentModel
		.reflist('highlight_equip')
		.filter(item => item.asModel.bool('marketable', true));
	const usedCar = store.state.content?.contentType === 'UsedPricesheet';

	const moreData = (
		<p
			className='small more-data'
			onClick={() => addOverlay(store, OverlayEnum.Consumption)}
		>
			{i18next.t('LinkAdditionalConsumptionData')}
			<svg className='icon-12'>
				<use xlinkHref='#forward-xsmall' />
			</svg>
		</p>
	);

	const engineDisclaimers = engine.arr('disclaimers') as Array<{
		Key: string;
		Value: string;
	}>;
	const disclaimerTags: Array<string> = [];
	['consumption.fuel-type'].forEach(tag => {
		if (engineDisclaimers.findIndex(dis => dis.Key === tag) !== -1)
			disclaimerTags.push(tag);
	});
	const getDisclaimerFromTag = (tag: string) =>
		disclaimerTags.includes(tag)
			? engineDisclaimers.findIndex(d => d.Key === tag) + 1
			: undefined;

	const [showMoreOpen, setShowMoreOpen] = useState<boolean>(false);

	const setup = getCookie('setup');

	// const maxHeightTeaserBox = (window.innerWidth - 2 * 58) * 0.565 * (9 / 16);

	return (
		<>
			<div className='upper-table overview'>
				{engine.str('engine_type') === 'HybridPetrol' ? (
					<div className='hybrid power'>
						{!(
							engine.str('max_output_kw') &&
							engine.str('max_output_ps')
						) ? (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								h1={engine.str('fuel_power', '-')}
								unit={i18next.t('FuelPowerUnit')}
								h1p2={engine.str('fuel_power_PS', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxSystemPower'}
								h1={engine.str('max_output_kw', '-')}
								unit={i18next.t('FuelPowerUnit')}
								h1p2={engine.str('max_output_ps', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				) : engine.str('engine_type') === 'Electric' ? (
					<div className='power'>
						{!(
							engine.str('max_output_kw') &&
							engine.str('max_output_ps')
						) ? (
							<UpperTableElement
								headline={'OverviewBoxElectricPower'}
								h1={engine.str('fuel_power2', '-')}
								unit={i18next.t('FuelPowerUnit')}
								h1p2={engine.str('fuel_power2_PS', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxElectricPower'}
								h1={engine.str('max_output_kw', '-')}
								unit={i18next.t('FuelPowerUnit')}
								h1p2={engine.str('max_output_ps', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				) : (
					<div className='power'>
						{!(
							engine.str('max_output_kw') &&
							engine.str('max_output_ps')
						) ? (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								h1={engine.str('fuel_power', '-')}
								unit={i18next.t('FuelPowerUnit')}
								h1p2={engine.str('fuel_power_PS', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								h1={engine.str('max_output_kw', '-')}
								unit={i18next.t('FuelPowerUnit')}
								h1p2={engine.str('max_output_ps', '-')}
								unitp2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				)}
				<div
					className={`${
						engine.str('engine_type') === 'HybridPetrol'
							? 'hybrid consumption'
							: 'consumption'
					}`}
					onClick={() => addOverlay(store, OverlayEnum.Consumption)}
				>
					<UpperTableElementNedcWltp
						headline={'OverviewBoxConsumption'}
						value={
							setup.market.name === 'MEX'
								? 'ObservedCombinedCO2Emissions'
								: 'xxx_consumption_electric_combined'
						}
						unit={
							setup.market.name === 'MEX'
								? i18next.t('WltpMexicoEmissionUnit')
								: 'ElectricConsumptionUnit'
						}
						model={vehicleDataModel}
						engine={engine}
						add={moreData}
						disclaimer={1}
					/>
				</div>
				{engine.str('engine_type') === 'Electric' ? (
					contentModel
						.ref('vehicle_data')
						.asModel.has('wltp_range_combined') ||
					contentModel
						.ref('vehicle_data')
						.asModel.has('nedc_range_combined') ? (
						<UpperTableElementNedcWltp
							headline={'OverviewBoxRangeCombined'}
							value={'xxx_range_combined'}
							unit={i18next.t('LengthUnit')}
							model={vehicleDataModel}
						/>
					) : engine.has('battery_charging_dc_100') ? (
						<UpperTableElement
							headline={'OverviewBoxChargingTime'}
							h1={engine.str('battery_charging_dc_100', '-')}
							unit={i18next.t('ChargingTimeUnit')}
						/>
					) : engine.has('battery_charging_ac_100') ? (
						<UpperTableElement
							headline={'OverviewBoxChargingTime'}
							h1={engine.str('battery_charging_ac_100', '-')}
							unit={i18next.t('ChargingTimeUnit')}
						/>
					) : engine.has('acceleration') ? (
						<UpperTableElementNumber
							headline={'OverviewBoxAcceleration'}
							value={engine.float('acceleration', NaN)}
							unit={'AccelerationUnit'}
						/>
					) : engine.has('top_speed') ? (
						<UpperTableElementNumber
							headline={'OverviewBoxTopSpeed'}
							value={engine.float('top_speed', NaN)}
							unit={'SpeedUnit'}
						/>
					) : engine.has('motor_drive') ? (
						<UpperTableElement
							headline={'OverviewBoxMotorDrive'}
							h1={engine.str('motor_drive', '-')}
						/>
					) : engine.has('motor_gear') ? (
						<UpperTableElement
							headline={'OverviewBoxMotorGear'}
							h1={engine.str('motor_gear', '-')}
						/>
					) : (
						<UpperTableElement
							headline={'OverviewBoxFuelType'}
							h1={engine.str('fuel2', '-')}
						/>
					)
				) : (
					<div
						className={`${
							engine.str('engine_type') === 'HybridPetrol'
								? 'hybrid fueltype'
								: 'fueltype'
						}`}
					>
						<UpperTableElement
							headline={'OverviewBoxFuelType'}
							h1={
								i18next
									.t(engine.str('engine_type'))
									.split(' ')[0]
							}
							h1p3={
								i18next
									.t(engine.str('engine_type'))
									.split(' ')[1]
							}
							disclaimer={getDisclaimerFromTag(
								'consumption.fuel-type'
							)}
						/>
					</div>
				)}
				{usedCar && (
					<>
						<div className='plus-btn-container'>
							<button
								className='showMore'
								onClick={() => {
									setShowMoreOpen(!showMoreOpen);
								}}
							>
								<svg className='icon-48'>
									<use
										xlinkHref={
											showMoreOpen
												? '#cancel-circle'
												: '#add-circle'
										}
									/>
								</svg>
								<p>
									{i18next.t(
										!showMoreOpen
											? 'ShowMoreInformation'
											: 'ShowLess'
									)}
								</p>
							</button>
						</div>
						{showMoreOpen && (
							<>
								<UpperTableElement
									headline={'InitialRegistration'}
									h1={new Date(
										contentModel.obj('initialreg').Timestamp
									).toLocaleDateString(i18next.language, {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit',
									})}
								/>
								<UpperTableElement
									headline={'Mileage'}
									h3={contentModel
										.float('mileage', NaN)
										.toLocaleString(i18next.language)}
									unit={i18next.t('LengthUnit')}
								/>
								<UpperTableElement
									headline={'NextGeneralInspection'}
									h1={contentModel.str(
										'generalinspection',
										'-'
									)}
								/>
								<UpperTableElement
									headline={'WarrantyType'}
									h1={
										contentModel.bool('warranty')
											? i18next.t('WarrantyTrue')
											: i18next.t('WarrantyFalse')
									}
								/>
							</>
						)}
					</>
				)}
			</div>
			{highlightEquip.map((equipItem, index) => (
				<div
					className='teaser-box'
					key={`teaser-box-${index}`}
					onClick={() => addOverlay(store, equipItem)}
				>
					<TeaserImage
						equipItem={equipItem}
						iconVideo='#system-play'
					/>
					<div className='teaser-box-right'>
						<p className='small category'>
							{(equipItem.asModel.target.equipcategory &&
								equipItem.asModel.target.equipcategory[
									i18next.language
								]) ||
								equipItem.asModel.str('equipcategory')}
						</p>
						<div className='truncate'>
							<h3>
								{(equipItem.asModel.target.layertitle &&
									equipItem.asModel.target.layertitle[
										i18next.language
									]) ||
									equipItem.asModel.str('layertitle')}
							</h3>
							<p
								className='long'
								dangerouslySetInnerHTML={checkHtml(
									equipItem.asModel.str('teasertext'),
									i18next.t('ClickMe')
								)}
							/>
						</div>

						<button>
							{i18next.t('LearnMore')}
							<svg className='icon-24'>
								<use xlinkHref='#forward' />
							</svg>
						</button>
					</div>
				</div>
			))}

			<div className='disclaimers pre-wrap'>
				1 {i18next.t('ConsumptionDisclaimer')}
				{disclaimerTags.length > 0 && (
					<Disclaimers
						tagsUsed={disclaimerTags}
						complete={engineDisclaimers}
					/>
				)}
			</div>
		</>
	);
}
