import React, { useContext, useState } from 'react';
import './styles.scss';
import { addOverlay, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { sortAlphabeticallyByKey } from '../../did-utility/src/Functions/EquipListManagement';
import { showInfoIcon } from '../../did-utility/src/Functions/showInfoIcon';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import clickLinks from '../../did-utility/src/Functions/clickLinks';
import i18next from 'i18next';
import { TeaserImage } from '../../did-utility/src/Components/TeaserImage';

export default function Equipment(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	let sibItems = [] as Array<SSPContentVO>;
	if (contentModel.has('color_in_image')) {
		sibItems = contentModel
			.ref('color_in_image')
			.asModel.reflist('packageItems')
			.filter(item => item.asModel.str('family') === 'SIB');
	}
	const languages = Object.keys(i18next.options.resources as Object);
	let padTypeValue;
	if (sibItems.length > 0 && languages.length > 1) {
		padTypeValue = sibItems[0].asModel.target.layertitle[i18next.language];
	} else if (sibItems.length > 0 && languages.length === 1) {
		padTypeValue = sibItems[0].asModel.str('layertitle');
	} else {
		padTypeValue = null;
	}
	const colors = [
		{ name: 'ColorOut', key: 'color_out' },
		{ name: 'ColorSeat', key: 'color_seat' },
		{
			name: 'PadType',
			value: padTypeValue,
		},
		{ name: 'ColorArm', key: 'color_arm' },
		{ name: 'ColorCarpet', key: 'color_carpet' },
		{ name: 'ColorSky', key: 'color_sky' },
	].filter(
		color =>
			(color.key &&
				contentModel.has(color.key) &&
				contentModel.str(color.key).trim() !== '') ||
			color.value
	);
	const serieEquip = contentModel.reflist('serie_equip');
	const specialEquip = contentModel.reflist('special_equip');

	const [additionalSerialEquipOpen, setAdditionalSerialEquipOpen] =
		useState<boolean>(false);

	const tileElement = (item: SSPContentVO | null, index: number) => {
		if (item === null)
			return (
				<div
					className='upper-table-element empty'
					key={`upper-table-element-${index}`}
				/>
			);

		return (
			<div
				className='upper-table-element'
				key={`upper-table-element-special-${index}`}
				onClick={() => {
					if (showInfoIcon(item)) {
						addOverlay(store, item);
					}
				}}
			>
				<TeaserImage equipItem={item} iconVideo='#system-play' />
				<p className='short'>
					{(item.asModel.target.layertitle &&
						item.asModel.target.layertitle[i18next.language]) ||
						item.asModel.str('layertitle')}
				</p>
			</div>
		);
	};

	const noTileCategory = (
		category: string,
		equipItems: Array<SSPContentVO>
	) => (
		<React.Fragment key={`no-tile-${category}`}>
			<div className='table-element headline'>
				<h6 className='bold'>{category}</h6>
			</div>
			{equipItems &&
				equipItems.map((equipItem, index) => {
					const item = equipItem.asModel;
					const showIcon = showInfoIcon(equipItem);
					return (
						<div
							key={`no-tile-${category}-${index}`}
							className={`table-element${
								showIcon ? ' with-end' : ''
							}`}
							onClick={() => {
								if (showIcon) {
									addOverlay(store, equipItem);
								}
							}}
						>
							<p
								className='short'
								onClick={e => clickLinks(e, store)}
								dangerouslySetInnerHTML={checkHtml(
									(item.target.layertitle &&
										item.target.layertitle[
											i18next.language
										]) ||
										item.str('layertitle'),
									i18next.t('ClickMe')
								)}
							/>
							{showIcon && (
								<div className='info-icon'>
									<svg>
										<use xlinkHref='#system-info' />
									</svg>
								</div>
							)}
						</div>
					);
				})}
		</React.Fragment>
	);

	const expandBtn = (
		<div className='plus-btn-container'>
			<button
				onClick={() => {
					setAdditionalSerialEquipOpen(!additionalSerialEquipOpen);
				}}
			>
				<svg className='icon-48'>
					<use
						xlinkHref={
							additionalSerialEquipOpen
								? '#cancel-circle'
								: '#add-circle'
						}
					/>
				</svg>
				<p>
					{i18next.t(
						!additionalSerialEquipOpen
							? 'ShowMoreSerialEquipment'
							: 'ShowLess'
					)}
				</p>
			</button>
		</div>
	);

	const equipmentCases = (list: Array<SSPContentVO>, limit?: boolean) => {
		const tiles: Array<SSPContentVO | null> = sortAlphabeticallyByKey(
			list.filter(
				item => item.asModel.has('assets') && showInfoIcon(item)
			),
			'layertitle'
		).filter(item => item.asModel.bool('marketable', true));

		if (tiles.length === 1) {
			tiles.push(null);
			tiles.push(null);
		} else if (tiles.length === 2) {
			tiles.push(null);
		}

		let noTileGroups: { [key: string]: Array<SSPContentVO> } = {};
		sortAlphabeticallyByKey(
			sortAlphabeticallyByKey(
				list.filter(item => !tiles.includes(item)),
				'layertitle'
			),
			'equipcategory'
		)
			.filter(item => item.asModel.bool('marketable', true))
			.forEach(function (i) {
				const category =
					(i.asModel.target.equipcategory &&
						i.asModel.target.equipcategory[i18next.language]) ||
					i.asModel.str('equipcategory');
				noTileGroups[category] = noTileGroups[category] || [];
				noTileGroups[category].push(i);
			});

		return (
			<>
				{tiles.length > 0 && (
					<div className='upper-table'>
						{tiles.slice(0, 9).map(tileElement)}
						{limit && tiles.length > 9 && expandBtn}
						{(additionalSerialEquipOpen || !limit) &&
							tiles.slice(9).map(tileElement)}
					</div>
				)}
				{limit &&
					tiles.length > 0 &&
					tiles.length <= 9 &&
					Object.keys(noTileGroups).length > 0 &&
					expandBtn}
				{(!limit || additionalSerialEquipOpen || tiles.length === 0) &&
					noTileCategory(
						Object.keys(noTileGroups)[0],
						noTileGroups[Object.keys(noTileGroups)[0]]
					)}
				{limit &&
					tiles.length === 0 &&
					Object.keys(noTileGroups).length > 1 &&
					expandBtn}
				{(additionalSerialEquipOpen || !limit) &&
					Object.keys(noTileGroups)
						.slice(1)
						.map(category =>
							noTileCategory(category, noTileGroups[category])
						)}
			</>
		);
	};

	return (
		<>
			{colors.length > 0 && (
				<div className='colors'>
					<h4 className='equipment-headline'>
						{i18next.t('Colors')}
					</h4>
					{colors.map(color => (
						<div
							className='table-element'
							key={`equipment-tab-color-${color.key}`}
						>
							<h6 className='start'>{i18next.t(color.name)}</h6>
							<p className='short middle with-end'>
								{color.key
									? contentModel.target[color.key][
											i18next.language
									  ]
									: color.value}
							</p>
						</div>
					))}
				</div>
			)}
			{specialEquip.length > 0 && (
				<>
					<h4 className='equipment-headline'>
						{i18next.t('ExtraEquipment')}
					</h4>
					{equipmentCases(specialEquip)}
				</>
			)}
			{serieEquip.length > 0 && (
				<>
					<h4 className='equipment-headline margin'>
						{i18next.t('SerialEquipment')}
					</h4>
					{equipmentCases(serieEquip, true)}
				</>
			)}
		</>
	);
}
